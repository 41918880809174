import { Widget } from '@typeform/embed-react'
import { Profile, UserType } from '../../HomePage';
import styled from 'styled-components';
import { useContext, useEffect } from 'react';
import { ProfileContext } from '../../HomePage';
import { getRequestHeaders } from '../../Utils/requestHelpers';
import { useNavigate } from 'react-router-dom';

const StyledWidget = styled(Widget)`
    width: 100%;
    height: 100%;
    `;

const Container = styled.div`
    * {
      margin: 0;
      padding: 0;
      position: absolute
    }
    html, body, iframe {
      border-radius: 0 !important;
    }
    body > #root > div {
      height: 100vh;
    }
  `;
  
export const BlueButtonWhiteText = styled.a`
    background-color: #009dff;
    border-radius: 5px;
    color: #EFEFEF;
    text-decoration: none;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    height: 15px;
`;

const sendToDB = async (userId: string, event: any, setProfile: React.Dispatch<React.SetStateAction<Profile | null>>) => {
    const { responseId } = event;
    console.log(responseId);
    var requestOptions: RequestInit = {
      method: 'PATCH',
      credentials: 'same-origin',
      headers: await getRequestHeaders(),
      body: JSON.stringify({
      responseId: responseId
    })
    };
      fetch(`/api/users/update-from-typeform`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
            console.log('Success:', data.id, data.name, data.email, data.userType);
            setProfile({
                  id: data.id,
                  name: data.name,
                  email: data.email,
                  userType: data.userType,
            });
        })
        .catch((error) => {
            console.error('Error occurred in sending data to database', error);
        });
}

export const Signup = () => {
  let { profile, setProfile } = useContext(ProfileContext);
  //sendToDB(props.setProfile);
  /*const navigate = useNavigate();
  useEffect(() => {
    if (profile?.userType !== UserType.NEWUSER) { 
      navigate('/');
    }
  }, [profile])*/
  return (
    //<>{profile != null && profile.userType == UserType.NEWUSER ? 
    (<Container>
      <StyledWidget
          id="hxPATelm"
          data-tf-container = {document.body}
          data-tf-size = {100}
          data-tf-opacity="0"
          data-tf-iframe-props="title=EdWorldo Registration"
          data-tf-transitive-search-params
          data-tf-disable-scroll
          data-tf-auto-resize
          data-tf-medium="snippet"
          onSubmit={event => {profile = profile as Profile; sendToDB(profile.id, event, setProfile)}}
      />
    </Container>) //: <Navigate to = '/' replace = {true}/>}</>
  )
}