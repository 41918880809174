import styled from "styled-components";
import logo from "../Images/Logo.png";
import { useContext, useState } from "react";
import { ProfileContext } from "../HomePage";
import { GoogleProfile, GoogleSignOut } from "./Auth/GoogleAuth";
import { LoadingButton } from "@mui/lab";
import { loadingButtonStyles } from "../HomePage";

const Wrapper = styled.div`
  background-color: white;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 20px;
  border-bottom: 1px solid #636363;
  //box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  //margin: 0px;
  //box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 0px inset;
`;

const Logo = styled.img.attrs({
  src: `${logo}`,
})`
  max-height: 70px;
  cursor: pointer;
`;

const RightSubWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Help = styled.img.attrs({
  src: "https://api.iconify.design/charm/help.svg",
})`
  height: 40px;
  width: 40px;
  cursor: pointer;
`;

//makeshift profile picture
//will change to actual img element in the future
/*const Profile = styled.div`
      background-color: purple;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      border: solid gray;
      cursor: pointer;
`;*/

const Divider = styled.hr`
  color: #636363;
  width: 100%;
  height: 1px;
  margin: 0px;
`;

const Greeting = styled.div`
  font-size: px;
  color: #434343;
  margin-right: 10px;
  fontSize: "12px",
  fontWeight: "600",
  display: block,
  flex: 1 0 auto;
`;

function Header() {
  let { profile, setProfile } = useContext(ProfileContext);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = async () => {
    setIsLoggingOut(true);
    await GoogleSignOut({ profile, setProfile });
    setIsLoggingOut(false);
  };

  return (
    <>
      <Wrapper>
        <Logo />
        <RightSubWrapper>
          {profile && (
            <>
              <GoogleProfile />
              <LoadingButton
                onClick={handleLogout}
                loading={isLoggingOut}
                sx={{ ...loadingButtonStyles }}
              >
                <span>Logout</span>
              </LoadingButton>
            </>
          )}
        </RightSubWrapper>
      </Wrapper>
    </>
  );
}

export default Header;
