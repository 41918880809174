import React from 'react'
import { useState } from 'react';
import { render } from '@testing-library/react';
import styled from 'styled-components';
import AssignmentDropdown from './Dropdown';

const Container= styled.ul`
    padding-left: 0px;
    //border: 1px solid #000000;
    width: 100%;
`;

const ClassItem= styled.div`
      width: 1007px;
      height: auto;
      margin-bottom: 10px;
      box-sizing: border-box;
      border: 1px solid #000000;
      border-radius: 5px;
      display: grid;
      grid-template-columns: 1fr auto;
      //background-color: #EDEDED;

      align-items: center;
      padding: 0px 10px;
      position: relative;
      right: 34px
      > AssignmentItem {
            background-color: white;
            margin-bottom: 10px;
            box-sizing: border-box;
            border: 1px solid #000000;
            border-radius: 5px;
            grid-column: span 1;
            width: 100%
            padding: 0px 10px;
            position: relative;
            {//right: 34px}  
            list-style: none;
        }
`;

const Dropdown= styled(AssignmentDropdown)`
      position: relative;
      display: block;
`;

const AssignmentText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    line-height: 29px;
    margin-top: 12px;
    margin-bottom: 12px;

    color: #434343;
    font-weight: 600;
    font-size: 24px;
`
const TriangleButtonDivDown = styled.div`
    border-style: solid;

    height: 0px;
    width: 0px;
    border-color: #434343 transparent transparent;
    border-width: 1rem 0.66rem 0;
    cursor: pointer;
    border-radius: 3px;
`
const TriangleButtonDivUp = styled.div`
    border-style: solid;

    height: 0px;
    width: 0px;
    border-color: transparent transparent #434343;
    border-width: 0rem 0.66rem 1rem;
    cursor: pointer;
    border-radius: 3px;
`

const ClassBoxes = (props: {currentData: {name: string, assignments: {name: string, id: string, date: Date, grade: number, link: string}[]}[]}) => {
    const [showVar, setShowVar] = useState(-1);
    function toggleShow(i: number) {
            if(showVar === i)
            {
                setShowVar(-1);
            }
            else
            {
                setShowVar(i);
            }
    }
    return (
        

        <Container>              
                {props.currentData.map((val, index) => {
                    return (
                    <ClassItem key={index}>
                        <AssignmentText>{val.name}</AssignmentText>
                        <a href='#' onClick={() => toggleShow(index)}>
                            {index !== showVar && <TriangleButtonDivDown></TriangleButtonDivDown>}
                            {index === showVar && <TriangleButtonDivUp></TriangleButtonDivUp>}
                        </a>
                        <AssignmentDropdown index={index} show={showVar} assignments = {val.assignments}></AssignmentDropdown>                        
                    </ClassItem>
                    );
                })}            
        </Container>
      )
}

export default ClassBoxes

/*function AssignmentBoxes(currentData: {name: string, link: string}[]) {
    
  }*/