import React from 'react'
import { useState, useEffect } from 'react';
import { render } from '@testing-library/react';
import styled from 'styled-components';
import Assign from './Assign';
import { TrashIcon } from './TeacherHomePage/SideBar';
import { getRequestHeaders } from '../Utils/requestHelpers';
import { BlueButtonWhiteText } from './Auth/Signup';
import { ClassListMap, OutlineData } from './TeacherHomePage/OutlineList';


const Container= styled.ul`
      width: calc(100% - 24px);
      padding: 12px;
      background-color: white;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const OutlineItem= styled.li`
      
      padding: 10px;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      
      &:hover {
        background-color:#e6eaee;
      }
`;

const AssignmentTopRow = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
`;

const AssignmentName = styled.h3`
      color: #434343;
      font-weight: 600;
      font-size: 16px;
      margin: 0px;
`
const Buttons = styled.div`
    display: flex;
    gap: 10px;
    padding: 5px;
`;

const OutlineLink = styled.div`
    display: contents;
    width: 100%;
    height: 100%;
    cursor: pointer;
`

const Boxes = (props: {setShowAssignmentList: React.Dispatch<React.SetStateAction<string | null>>, setData: React.Dispatch<React.SetStateAction<{name: string; link: string; outlineId: string;}[]>>,
              currentData: {name: string, link: string, outlineId: string}[], classListData: { classroom: string; code: string; id: string}[]
              setHeaderText: React.Dispatch<React.SetStateAction<string>>}) => {
    
    const [showVar, setShowVar] = useState(-1);

    function toggleShow(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, i: number) {
        e.stopPropagation();
            if(showVar === i)
            {
                setShowVar(-1);
            }
            else
            {
                setShowVar(i);
            }
    }

    const removeOutline = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, outlineId: string) => {
        e.stopPropagation();
        removeOutlineOnDB(outlineId);
        props.setData(currentData => currentData.filter(item => item.outlineId !== outlineId));
    }

    const handleShowAssignment = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, assignmentName: string, outlineId: string) => {
        e.stopPropagation();
        props.setShowAssignmentList(outlineId);
        props.setHeaderText(`${assignmentName} Grades`);
        
    }

    const handleOutlineLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, link: string, outlineId: string) => {
        //console.log('Propagation didnt stop');
        window.location.href = `${link}?edworldo=create&edworldo_id=${outlineId}`
    }

    return (
        <Container>              
                {props.currentData.map((val, index) => {
                    return (
                        <OutlineLink onClick = {(e) => handleOutlineLink(e, val.link, val.outlineId)} key={index}>
                            <OutlineItem>
                                <AssignmentTopRow>
                                    <AssignmentName>{val.name}</AssignmentName>
                                    <Buttons onClick = {(e) => {e.stopPropagation()}}>
                                        <BlueButtonWhiteText href='#' onClick={(e) => toggleShow(e, index)}>Assign</BlueButtonWhiteText> {/*Actually should be button, but want styling of a link*/}
                                        <BlueButtonWhiteText href='#' onClick={(e) => handleShowAssignment(e, val.name, val.outlineId)}>Grades</BlueButtonWhiteText>
                                        <TrashIcon onClick = {(e) => removeOutline(e, val.outlineId)}>delete</TrashIcon>
                                    </Buttons>
                                </AssignmentTopRow>
                                <Assign index={index} show={showVar} outlineId = {val.outlineId}
                                classListData = {props.classListData}></Assign>                        
                            </OutlineItem>
                        </OutlineLink>
                    );
                })}
        </Container>
      )
}

{/* <ShowAssignment onClick = {() => {handleShowAssignment(val.name)}}>*/}
                        
{/*</ShowAssignment>*/}

const removeOutlineOnDB =  async (outlineId: string) =>
  {
      var requestOptions: RequestInit = {
            method: 'DELETE',
            credentials: 'same-origin',
            headers: await getRequestHeaders()
      };
      
        const fetchData = () => {fetch(`/api/outlines/${outlineId}`, requestOptions)
           .then(() => console.log("Outline Deleted"))
           .catch(error => console.log('error', error));
            }
        fetchData();
}

export default Boxes