import React from 'react'
import { useState } from 'react';
//import { render } from '@testing-library/react';
import styled from 'styled-components';
import AssignmentDropdown from './AssignmentDropdown';
import { AssignmentListComponent } from './AssignmentList';

export enum Submitted {
    ONTIME = 0,
    LATE = 1,
    INCOMPLETE = 2,
  }

const Container= styled.ul`
    padding-left: 0px;
    //border: 1px solid #000000;
    width: 100%;
`;

const ClassItem= styled.div`
      width: 1007px;
      height: auto;
      margin-bottom: 10px;
      box-sizing: border-box;
      //border: 1px solid #000000;
      border-radius: 5px;
      display: grid;
      grid-template-columns: 1fr auto;
      //background-color: #EDEDED;
      background-color: white;
      align-items: center;
      padding: 0px 10px;
      position: relative;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      > AssignmentItem {
            background-color: white;
            margin-bottom: 10px;
            box-sizing: border-box;
            border: 1px solid #000000;
            border-radius: 5px;
            grid-column: span 1;
            width: 100%
            padding: 0px 10px;
            position: relative;
            {//right: 34px}  
            list-style: none;
        }
`;

const Dropdown= styled(AssignmentDropdown)`
      position: relative;
      display: block;
`;

const AssignmentText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    line-height: 29px;
    margin-top: 12px;
    margin-bottom: 12px;

    color: #434343;
    font-weight: 600;
    font-size: 24px;
`
const TriangleButtonDivDown = styled.div`
    border-style: solid;

    height: 0px;
    width: 0px;
    border-color: #434343 transparent transparent;
    border-width: 1rem 0.66rem 0;
    cursor: pointer;
    border-radius: 3px;
`
const TriangleButtonDivUp = styled.div`
    border-style: solid;

    height: 0px;
    width: 0px;
    border-color: transparent transparent #434343;
    border-width: 0rem 0.66rem 1rem;
    cursor: pointer;
    border-radius: 3px;
`

const AssignmentBoxes = (props: {currentData: AssignmentListComponent[]}) => {
    const [showVar, setShowVar] = useState(-1);
    function toggleShow(i: number) {
            if(showVar === i)
            {
                setShowVar(-1);
            }
            else
            {
                setShowVar(i);
            }
    }

    let submittedGrouping: AssignmentListComponent[][] = [[], [], []];

    for (let assignment of props.currentData) {
        if (assignment.submittedAt == null) {
            submittedGrouping[Submitted.INCOMPLETE].push(assignment);
        }
        else if (assignment.submittedAt <= assignment.dueDate) {
            submittedGrouping[Submitted.ONTIME].push(assignment);
        }
        else if (assignment.submittedAt > assignment.dueDate) {
            submittedGrouping[Submitted.LATE].push(assignment);
        }
        else {
            alert('Something went wrong');
        }
    }

    return (
        

        <Container>              

                    <ClassItem>
                        <AssignmentText style = {{color: '#006600'}}>Finished: {submittedGrouping[Submitted.ONTIME].length} assignments</AssignmentText>
                        <a href='#' onClick={() => toggleShow(Submitted.ONTIME)}>
                            {Submitted.ONTIME !== showVar && <TriangleButtonDivDown></TriangleButtonDivDown>}
                            {Submitted.ONTIME === showVar && <TriangleButtonDivUp></TriangleButtonDivUp>}
                        </a>
                        <AssignmentDropdown index={Submitted.ONTIME} show={showVar} assignments = {submittedGrouping[Submitted.ONTIME]}></AssignmentDropdown>                        
                    </ClassItem>

                    <ClassItem>
                        <AssignmentText style = {{color: '#997a00'}}>Late: {submittedGrouping[Submitted.LATE].length} assignments</AssignmentText>
                        <a href='#' onClick={() => toggleShow(Submitted.LATE)}>
                            {Submitted.LATE !== showVar && <TriangleButtonDivDown></TriangleButtonDivDown>}
                            {Submitted.LATE === showVar && <TriangleButtonDivUp></TriangleButtonDivUp>}
                        </a>
                        <AssignmentDropdown index={Submitted.LATE} show={showVar} assignments = {submittedGrouping[Submitted.LATE]}></AssignmentDropdown>                        
                    </ClassItem>

                    <ClassItem>
                        <AssignmentText style = {{color: '#660000'}}>Incomplete: {submittedGrouping[Submitted.INCOMPLETE].length} assignments</AssignmentText>
                        <a href='#' onClick={() => toggleShow(Submitted.INCOMPLETE)}>
                            {Submitted.INCOMPLETE !== showVar && <TriangleButtonDivDown></TriangleButtonDivDown>}
                            {Submitted.INCOMPLETE === showVar && <TriangleButtonDivUp></TriangleButtonDivUp>}
                        </a>
                        <AssignmentDropdown index={Submitted.INCOMPLETE} show={showVar} assignments = {submittedGrouping[Submitted.INCOMPLETE]}></AssignmentDropdown>                        
                    </ClassItem>
                    
        </Container>
      )
}

export default AssignmentBoxes

/*function AssignmentBoxes(currentData: {name: string, link: string}[]) {
    
  }*/