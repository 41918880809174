import { useContext, useEffect } from "react";
import { ProfileContext } from "../HomePage";
import { getRequestHeaders } from "./requestHelpers";
import { getToken } from "./requestHelpers";

interface GetProfileProps {
  setIsLoading: (loading: boolean) => void;
}

const GetProfile: React.FC<GetProfileProps> = ({ setIsLoading }) => {
  console.log("GetProfile is called");
  const { profile, setProfile } = useContext(ProfileContext);

  useEffect(() => {
    const getProfile = async () => {
      var requestOptions: RequestInit = {
        method: 'GET',
        headers: await getRequestHeaders(),
      };
      fetch(`/api/users`, requestOptions)
        .then((response) => {
          if (response.status === 401) {
            setProfile(null);
            console.log('did we get here?');
            throw new Error('Unauthorized');
          }
          return response.json();
        })
        .then((data) => {
          let updatedProfile = {
            id: data.id,
            name: data.name,
            email: data.email,
            userType: data.userType,
          };
          setProfile(updatedProfile);
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.message !== 'Unauthorized') {
            setProfile(null);
            console.error('Error occurred in getting profile', error);
          }
          setIsLoading(false);
        });
    };
    getProfile();
  }, [setProfile, setIsLoading]);

  useEffect(() => {
    console.log("Profile:", profile);
  }, [profile]);

  return null; // Return null as we don't want to render any JSX from this component
};

export default GetProfile;
