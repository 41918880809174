import { useState, useEffect, useContext } from "react";
import { ProfileContext, Profile } from "../../HomePage";
import { googleLogout, GoogleLogin } from "@react-oauth/google";
import { getRequestHeaders } from "../../Utils/requestHelpers";
import styled from "styled-components";
import { Avatar } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { ExtensionState } from "../../HomePage";

//import { AvailableInTheChromeStore } from "../../AvailableInTheChromeStore.png"



const CenteredContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 70vh;
    box-sizing: border-box;
  `;

const Header = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 39px;
  display: flex;
  align-items: center;
  color: #636363;
  margin-bottom: 12px;
`;

export function GoogleSignIn() {
  const { profile, setProfile, extensionState } = useContext(ProfileContext);
  const [token, setToken] = useState<any>(null);

  /*const navigate = useNavigate();
  useEffect(() => {
    if (profile != null) { 
      navigate('/');;
    }
  }, [profile])*/

  const responseMessage = (response: any) => {
    setToken(response);
  };
  const errorMessage = () => {
    console.log("Error with Google Login");
  };

  useEffect(() => {
    console.log(token);
    if (token) {
      const SignInOnDB = () => {
        var requestOptions: RequestInit = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token.credential,
          },
        };
        const sendData = () => {
          fetch(`/api/auth/google`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              localStorage.setItem("accessToken", data.tokens.accessToken);
              localStorage.setItem("refreshToken", data.tokens.refreshToken);
              setProfile({
                id: data.user.id,
                name: data.user.name,
                email: data.user.email,
                userType: data.user.userType,
              });
            })
            .catch((error) => {
              console.error("Error occurred in signing in with Google", error);
            });
        };
        sendData();
      };
      SignInOnDB();
    } else {
      console.log("No token");
    }
  }, [token]);

  var AvailableInTheChromeStore = require("../../AvailableInTheChromeStore.png");

  if (extensionState == ExtensionState.UPTODATE) {
    return (
      <>
        <CenteredContainer>
          <Header>Sign in below to get started</Header>
          <GoogleLogin data-testid="custom-google-login" shape="pill" size="large" onSuccess={responseMessage} onError={errorMessage} />
        </CenteredContainer>
      </>
    );
  }
  else if (extensionState == ExtensionState.UPDATEREQUIRED) {
    return (
      <>
      <CenteredContainer>
        <Header>Extension update required and refresh before continuing</Header>
        <a href = 'https://chrome.google.com/webstore/detail/edworldo/anlklifcmcjdhibgpoljlolobmafbakp'>
          <img src = {AvailableInTheChromeStore.default} alt = 'EdWorldo Chrome Store Link' style={{marginTop: '20px'}}/>
        </a>
      </CenteredContainer>
    </>
    )
  }
  else {
    return (
    <>
      <CenteredContainer>
        <Header>Download the EdWorldo chrome extension and refresh before continuing</Header>
        <a href = 'https://chrome.google.com/webstore/detail/edworldo/anlklifcmcjdhibgpoljlolobmafbakp'>
          <img src = {AvailableInTheChromeStore.default} alt = 'EdWorldo Chrome Store Link' style={{marginTop: '20px'}}/>
        </a>
      </CenteredContainer>
    </>
    )
  }
}

interface ProfileStateProps {
  profile: Profile | null;
  setProfile: React.Dispatch<React.SetStateAction<Profile | null>>;
}

export async function GoogleSignOut(props: ProfileStateProps) {
  const { profile, setProfile } = props;
  googleLogout();

  const logoutOnDB = async () => {
    var requestOptions: RequestInit = {
      method: "POST",
      headers: await getRequestHeaders(),
    };
    if (profile == null) return;
    await fetch(`/api/auth/logout`, requestOptions)
      .then((response) => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      })
      .catch((error) => {
        console.error("Error occurred when logging out", error);
      });
    setProfile(null);
  };
  await logoutOnDB();

  return;
}

export function GoogleProfile() {
  const { profile } = useContext(ProfileContext);

  if (!profile) {
    return null;
  }

  return (
    <Tooltip title={`${profile?.name} (${profile?.email})`}>
      <Avatar alt={profile?.name} />
    </Tooltip>
  );
}