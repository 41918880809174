import React, { Component } from 'react'
import { render } from '@testing-library/react';
import SideBar from './Components/TeacherHomePage/SideBar'
import OutlineList from './Components/TeacherHomePage/OutlineList'
import Header from './Components/Header';
import styled from 'styled-components';
import { useContext, useEffect } from 'react';
import { ProfileContext, UserType } from './HomePage';
import { useNavigate, Outlet } from "react-router-dom";

const Container= styled.div`
    background-color: #e6eaee;
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: row;
    @media (max-width: 560px) {
      flex-direction: column;
    }
`

const Content= styled(OutlineList)`
    flex-grow: 1;
    display: flex;
    position: static;
    flex-direction: column;
    align-items: center;
`;

const TeacherHomePage = () => {
    const { profile } = useContext(ProfileContext);
    //const navigate = useNavigate();
    /*useEffect(() => {
      if (profile?.userType !== UserType.TEACHER) { 
        navigate('/');
      }
    }, [profile]);*/
    return (
      <>
        <Container>
            <OutlineList />
        </Container>
      </>
    );
}

export default TeacherHomePage