import React from 'react'
import { useState, useEffect } from 'react';
//import { render } from '@testing-library/react';
import { addBox, AssignmentListData } from './AssignmentListData';
import styled from 'styled-components';
import AssignmentBoxes from './AssignmentBoxes';
import { Wrapper } from '../StudentHome/ClassList';
import { getRequestHeaders } from '../../Utils/requestHelpers';

export type AssignmentListComponent = {name: string, link: string, grade: number, dueDate: Date, submittedAt: Date, id: number}

const Container= styled.div`
      max-width: 600px;
      margin: auto;
`;

const AddAssignment= styled.div`
      padding: 10px;
      gap: 10px;
      background-color: whitesmoke;
      border: 1px solid gray;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto auto;
`;

const Input= styled.input`
      width: 100%
`;


export function AssignmentList(props: {outlineId: string}) {
      let data: AssignmentListComponent[] = [];
      const [currentData, setData] = useState(data);
      useEffect(() => {
        if (currentData.length == 0)
        SetUpAssignmentListData(props.outlineId, setData);
      }, []);
      //const[isJoining, setIsJoining] = useState(false);  
  
      return (
        <AssignmentBoxes currentData = {currentData}></AssignmentBoxes>
      );
  }

  
  async function SetUpAssignmentListData(outlineId: string, setData: React.Dispatch<React.SetStateAction<AssignmentListComponent[]>>) {
    const requestOptions: RequestInit = {
      method: 'GET',
      credentials: 'same-origin',
      headers: await getRequestHeaders()
    };
  
    try {
      const response = await fetch(`/api/outlines/${outlineId}/assignments`, requestOptions);
      const result = await response.json();
  
      const promises = result.map((assignmentInfo: any) => getAssignmentsFromId(assignmentInfo.id));
      const assignmentList = await Promise.all(promises);
      console.log(assignmentList);
      setData(assignmentList);
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  }
  
  async function getAssignmentsFromId(assignmentId: string) {
    const requestOptions: RequestInit = {
      method: 'GET',
      credentials: 'same-origin',
      headers: await getRequestHeaders()
    };
  
    try {
      const response = await fetch(`/api/assignments/${assignmentId}`, requestOptions);
      const result = await response.json();
      console.log(result);
      const assignment: AssignmentListComponent = {
        id: result.id,
        name: result.assignmentName,
        grade: result.score,
        link: result.outlinePages[0].url,
        dueDate: result.dueDate,
        submittedAt: result.submittedAt
      };
      return assignment;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  }

export default AssignmentList