import { addClass } from './SideBarData'
import { getRequestHeaders } from '../../Utils/requestHelpers';

async function CreateClass(userId: string, classroom: string) {
  let {code, id } = await UpdateClassOnDB(userId, classroom);
  addClass(classroom, code, id);
  return {"classroom": classroom, "code": code, "id": id}
}

function generateClassroomCode(length: number) {
  const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

async function generateUniqueClassroomCode() {
  let code;
  do {
    code = generateClassroomCode(6); // Change the length to generate a different code length
  } while (await checkIfCodeExists(code));
  return code;
}

async function checkIfCodeExists(code: string) {
  var requestOptions: RequestInit = {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
    'Content-Type': 'application/json'
  }
  };

  const response = await fetch(`/api/student-group/exists/${code}`, requestOptions);
  return response.status === 200;
}

async function UpdateClassOnDB(userId: string, classroom: string)
{

  var requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'same-origin',
    headers: await getRequestHeaders(),
  body: JSON.stringify({
    "Name": classroom,
  })
  };
  const response = await fetch(`/api/classrooms/`, requestOptions)
    /*.then((response) => (response.json()))
    .then((result) => {console.log("Success in creating class"); return result.id as string})
    .catch(() => {alert("Error occurred in creating the class")})
  }*/
  let result = await response.json();
  let code = result.joinCode as string;
  let id = result.id as string;
  return {"code": result.joinCode, "id": result.id };
  //useEffect(() => sendData, [])
}

export default CreateClass

//Have to add state functions to track when new classes are added.