import jwt from 'jsonwebtoken';

export function isTokenExpired(token: string | null, bufferTime = 0): boolean {
  if (!token) {
    return true;
  }

  try {
    const decodedToken: any = jwt.decode(token);
    if (!decodedToken || !decodedToken.exp) {
      return true;
    }

    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const expirationTimeInSeconds = decodedToken.exp;
    
    return currentTimeInSeconds + bufferTime >= expirationTimeInSeconds;
  } catch (error) {
    console.error('Error occurred while checking token expiration:', error);
    return true;
  }
}

export async function refreshAccessToken() {
  let refreshToken = localStorage.getItem("refreshToken");
  console.log(`Refresh Token: ${refreshToken}`);
  if (refreshToken) {
    let headers: { [key: string]: string } = {
      "Content-Type": "application/json",
      "Authorization": `${refreshToken}`,
    };
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: headers,
    };
    console.log('headers: ');
    console.log(requestOptions);
    console.log(window.location.host);
    await fetch(`/api/auth/refresh`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          throw new Error('Unauthorized');
        }
        return response.json();
      })
      .then((data) => {
        console.log("New refreshed tokens:", data);
        // Token has been refreshed
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
      })
      .catch((error) => {
        console.error("Error occurred in refreshing access token:", error)
      })
  } 
}
