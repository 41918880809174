import { DateTimePicker } from "@mantine/dates";

export default function CalendarPicker(props: {label: string, placeholder: any, 
  setDueDate: React.Dispatch<React.SetStateAction<Date | null>>}) {
  return (
    <DateTimePicker
      defaultValue = {oneWeekFromNow()}
      clearable = {true}
      valueFormat="MMM DD YYYY hh:mm A"
      label={props.label}
      placeholder={props.placeholder}
      maw={400}
      //variant="filled"
      mx="auto"
      yearsListFormat="YYYY"
      getYearControlProps={() => ({})}
      onChange = {(date) => {handleChange(date, props.setDueDate)}}
    />
  );
}

const handleChange = (dateValue: any, setDueDate: React.Dispatch<React.SetStateAction<Date | null>>) => {
  //console.log(dateValue);
  if (dateValue) {
    /*console.log(dateValue.year);
    const date = new Date(
      dateValue.year,
      dateValue.month - 1,
      dateValue.day,
      dateValue.hour,
      dateValue.minute,
      dateValue.second
    );*/
    //const date = dateValue.toDate();
    setDueDate(dateValue);
  }
  else {
    setDueDate(null);
  }
  
};

export function oneWeekFromNow(): Date {
  const date = new Date();
  date.setDate(date.getDate() + 7);
  return date;
}
