import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import { AlertColor } from '@mui/material';
import styled from 'styled-components';
import ReactPortal from 'react-dom';



const PopupWrapper = styled.div`
    display: flex;
    justify-content: center; /* Center horizontally */
    z-index: 1000;
`


const Popup = styled(Alert)`
    position: fixed !important;
    top: 5% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    transform: scale(1.3) !important;
    z-index: 1000;
`

const WebsiteButton = styled(Button)`
    top: 45% !important;
`;

export interface PopupMessageProps {
    popupType: string,
    message: string,
    boldedMessage: string,
    setPopupMessageProps: React.Dispatch<React.SetStateAction<PopupMessageProps | null>>;
    //onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const handleStoppingPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    console.log('Propagation shouldve stopped');
    e.stopPropagation();
}

export const PopupMessage = (props: PopupMessageProps) => {
    let element = <PopupWrapper onClick = {(e) => handleStoppingPropagation(e)}>
                    <Popup onClose={() => {props.setPopupMessageProps(null)}} severity = {props.popupType.toLowerCase() as AlertColor}>
                        <AlertTitle><strong>{props.popupType}</strong></AlertTitle>
                        {props.message} <strong>{props.boldedMessage}</strong>
                    </Popup>
                  </PopupWrapper>
    return ReactPortal.createPortal(element, document.body);
   
}