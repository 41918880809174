import React from 'react'

let data: {classroom: string, code: string, id: string}[]=[];

//addClass used when adding existing classes from server
//CreateClass.tsx used when creating a new class
export function addClass(classroom: string, code: string, id: string) {
  data.push({classroom, code, id});
  console.log(data);
}

/*export function removeClass(code: string) {
  console.log('removeClass');
  data.filter(item => item.code !== code)
  console.log(data);
  console.log(code);
}*/

export function getSideBar()
{
    return data;
}

export const SideBarData=data;