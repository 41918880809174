import { isTokenExpired, refreshAccessToken } from "./tokenHelpers";

export async function getRequestHeaders() {
  let accessToken = localStorage.getItem("accessToken"); 
  if (!accessToken) {
    console.log("No access token");
    return;
  }

  let headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };

  const bufferTime = 840;
  if (!isTokenExpired(accessToken, bufferTime)) {
    console.log("Token not expired");
    headers["Authorization"] = `Bearer ${accessToken}`;
  } else {
    await refreshAccessToken();
    accessToken = localStorage.getItem("accessToken");
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return headers;
}

export function getToken() { //Used for extensionProfile. Only used in case where user is logged in, so we know token exists
      return localStorage.getItem('accessToken');
}

export function getRefreshToken() { //Used for extensionProfile. Only used in case where user is logged in, so we know token exists
  return localStorage.getItem('refreshToken');
}
