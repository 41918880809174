import React, { Component, useContext, useEffect } from 'react'
import { render } from '@testing-library/react';
import SideBar from './Components/TeacherHomePage/SideBar'
import ClassList from './Components/StudentHome/ClassList';
import Header from './Components/Header';
import styled from 'styled-components';
import { ProfileContext } from './HomePage';
import { UserType } from './HomePage';
import { useNavigate, Navigate } from "react-router-dom";


const StudentHomePage = () => {
  const { profile } = useContext(ProfileContext);
  //const navigate = useNavigate();
  /*useEffect(() => {
    if (profile?.userType !== UserType.STUDENT) { 
      navigate('/');
    }
  }, [profile])*/
    return (
      <>
        {/*<Header/>*/}
        <ClassList />
      </>
    );
}

export default StudentHomePage