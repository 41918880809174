import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import dotenv from "dotenv";
import TeacherHomePage from "./TeacherHomePage";
import StudentHomePage from "./StudentHomePage";
import HomePage from "./HomePage";
import { useEffect } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Signup } from "./Components/Auth/Signup";
import { GoogleSignIn } from "./Components/Auth/GoogleAuth";
import ErrorPage from "./Utils/ErrorPage";

const LoadGoogleSignInScript = () => {
  const script = document.createElement("script");
  script.src = "https://accounts.google.com/gsi/client";
  script.async = true;
  script.defer = true;
  return script;
};

dotenv.config();
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "signin",
        element: <GoogleSignIn />,
      },
      {
        path: "signup",
        element: <Signup />,
        errorElement: <ErrorPage />,
      },
      {
        path: "teacher",
        element: <TeacherHomePage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "student",
        element: <StudentHomePage />,
        errorElement: <ErrorPage />,
      },
    ]
},
  {
    path: "/error",
    element: <ErrorPage/>,
    errorElement: <ErrorPage />,
  }
]);

if (clientId) {
  root.render(
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={clientId}>
        {<HomePage />}
        {/*<RouterProvider router={router} />*/}
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
} else {
  root.render(
    <React.StrictMode>
      <h1>Error</h1>
      <p>The Google client ID is not set.</p>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
