import React, { useState, createContext, useContext, useEffect } from "react";
import Header from "./Components/Header";
import TeacherHomePage from "./TeacherHomePage";
import StudentHomePage from "./StudentHomePage";
import { Signup } from "./Components/Auth/Signup";
import GetProfile from "./Utils/profileHelpers";
import { GoogleSignIn } from "./Components/Auth/GoogleAuth";
import { Box, CircularProgress } from "@mui/material";
import { getToken } from "./Utils/requestHelpers";
import styled from "styled-components";
import { useNavigate, Outlet } from "react-router-dom";
import { Extension } from "typescript";
import { getRefreshToken } from "./Utils/requestHelpers";
import { Navigate } from "react-router-dom";

export const loadingButtonStyles = {
  maxWidth: "75px",
  backgroundColor: "#009dff",
  borderRadius: "5px",
  color: "#efefef",
  textDecoration: "none",
  padding: "10px",
  fontSize: "12px",
  fontWeight: "600",
  border: "none",
  alignSelf: "flex-end",
  marginRight: "0px 10px 0px 10px",
  cursor: "pointer",
  textTransform: "none",
  "& .MuiCircularProgress-root": {
    color: "#fff", 
  },
};

export enum UserType {
  NEWUSER = 0,
  STUDENT = 1,
  TEACHER = 2,
}

export interface Profile {
  id: string;
  name: string;
  email: string;
  userType: UserType;
}

interface ProfileContextType {
  profile: Profile | null;
  setProfile: React.Dispatch<React.SetStateAction<Profile | null>>;
  extensionState: ExtensionState;
}

export enum ExtensionState {
  NOTINSTALLED = 0,
  UPDATEREQUIRED = 1,
  UPTODATE = 2,
}


export const ProfileContext = createContext<ProfileContextType>({
  profile: null,
  setProfile: () => {},
  extensionState: ExtensionState.NOTINSTALLED,
});

const CenteredBox = styled(Box)`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 100vh;: 100vh;
      flex-direction: column;
      box-sizing: border-box;
`;

export default function HomePage() {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [extensionState, setExtensionState] = useState(ExtensionState.NOTINSTALLED);
  const [loadPage, setLoadPage] = useState(false);

  useEffect(() => {
    let extensionProfile;
    if (profile) {
        extensionProfile = {
        id: profile?.id,
        name: profile?.name,
        email: profile?.name,
        userType: profile?.name,
        token: getToken(),
        refreshToken: getRefreshToken()
      }
    }
    else {
      extensionProfile = null;
    }
    //@ts-ignore
    if (chrome && chrome.runtime && chrome.runtime.sendMessage) {
      console.log('chrome.runtime.sendMessage');
      const extensionId = 'anlklifcmcjdhibgpoljlolobmafbakp';
      let password = 'edworldo_totallyunhackablepassword420';
      console.log(extensionProfile);
      console.log(getRefreshToken());
      if (process.env.NODE_ENV === 'production') {
        console.log('Running in production mode');
        password = 'edworldo_totallyunhackablepassword420_production';
      }
      let hasExtension = false;
      let upToDate = false;
      try {
      chrome.runtime.sendMessage(extensionId, {message: extensionProfile, password: password},
        function (response) {
          if (response) {
            if (response.version) {
              if ((+response.version) >= 1) {
                console.log('Extension present and up-to-date');
                upToDate = true;
              }
            }
            hasExtension = true;
            let extensionState = (hasExtension == true && upToDate == true) ? ExtensionState.UPTODATE : 
                                  hasExtension == true ? ExtensionState.UPDATEREQUIRED : ExtensionState.NOTINSTALLED
            setExtensionState(extensionState);
            setLoadPage(true);
          }
          else {
            console.log("No response received");
          }
        });
      }
      catch (error) {
        console.error(error);
      }
    } else {
      setExtensionState(ExtensionState.NOTINSTALLED);
      setProfile(null);
      console.log('chrome.runtime.sendMessage is not available');
      setLoadPage(true);
    }
  }, [profile]);

  /*useEffect(() => {
    console.log('Add On test ran');
    //@ts-ignore
    let gettingAllAddOns = chrome.management.getAll();
    gettingAllAddOns.then((addOnsArray: any) => isMyExtension(addOnsArray));
  }, [])*/

  //const navigate = useNavigate();

  /*useEffect(() => {
      console.log(profile);
      if (profile == null) {
        navigate('/signin');
      }
      else if (profile?.userType === UserType.NEWUSER) {
        navigate('/signup');
      }
      else if (profile.userType === UserType.TEACHER) {
        navigate('/teacher');
      }
      else if (profile.userType === UserType.STUDENT) {
        navigate('/student');
      }
      else {
        navigate('/error');
      }
  }, [profile])*/

  return (
    <ProfileContext.Provider value={{ profile, setProfile, extensionState }}>
      <GetProfile setIsLoading={setIsLoading} />
      {isLoading ? (
        <CenteredBox>
          <CircularProgress size={150} />
          <p> Preparing EdWorldo...</p>
          <p> If this page doesn't load within 10 seconds, please refresh the page or notify the owner: som.gupta@snssystem.com. </p>
        </CenteredBox>
      ) : (
        <>
          <Header />
          {/*<Outlet />*/}
          {/*profile == null ? <Navigate to='/signin' replace = {true}/> : null}
          {profile?.userType === UserType.TEACHER ? <Navigate to='/teacher' replace = {true}/> : null}
          {profile?.userType === UserType.STUDENT ? <Navigate to='/student' replace = {true}/> : null}
          {profile?.userType === UserType.NEWUSER ? <Navigate to='/signup' replace = {true}/> : null}*/}
          {profile == null ? <GoogleSignIn /> : null}
          {loadPage && profile?.userType === UserType.TEACHER ? <TeacherHomePage /> : null}
          {loadPage && profile?.userType === UserType.STUDENT ? <StudentHomePage /> : null}
          {loadPage && profile?.userType === UserType.NEWUSER ? <Signup /> : null}
        </>
      )}
    </ProfileContext.Provider>
  );
}

function isMyExtension(addOnsArray: any) {
      console.log(addOnsArray);
}


