import React from 'react'
import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import ClassBoxes from './ClassBoxes';
import { GetAssignmentData } from '../../ApiRoutes(IGNORE)';
import { ProfileContext } from '../../HomePage';
import { getRequestHeaders } from '../../Utils/requestHelpers';
import { LoadingButton } from "@mui/lab";
import { loadingButtonStyles } from "../../HomePage";

type Assignment = {
  name: string, 
  id: string,
  date: Date,
  grade: number,
  link: string
}

export type ClassAssignmentsComponent = {name: string, 
assignments : Assignment[]}

const ClassListData: ClassAssignmentsComponent[] = [];

/*const ClassListData: ClassAssignmentsComponent[] = [{name: "5th Period - Calculus", assignments : 
[{name: "test", date: new Date(), grade: 95, link: '#'}, {name: "test2", date: new Date(), grade: 100, link: '#'}]}, {name: "6th Period - Language Arts", assignments : 
[{name: "test", date: new Date(), grade: 95, link: '#'}, {name: "test2", date: new Date(), grade: 100, link: '#'}]}, {name: "7th Period - Human Geography", assignments : 
[{name: "The Demographic Transition Model", date: new Date(), grade: 0, link: '#'}, {name: "Thomas Malthus", date: new Date(), grade: 100, link: '#'}]}]
*/

export const Container= styled.div`
      margin: auto;
      margin-top: 20px;
`;

export const AddAssignment= styled.div`
      padding: 10px;
      gap: 10px;
      background-color: whitesmoke;
      border: 1px solid gray;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto auto;
`;

/*const Input= styled.input`
      width: 100%
`;*/

export const Wrapper= styled.div`
      display: flex;
      align-items: center;
`;

export const Title= styled.h1`
      {/*font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 31px;*/}
      color: #636363;
      display: grid;
      grid-template-columns: 1fr auto auto;
      gap: 10px;
      align-items: center;
      padding: 0px 10px;
      padding-left: 0px;
      margin-bottom: 10px;
      
`;

export const Header = styled.h1`
      font-size: 26px;
      color: #636363;
      font-weight: 700;
      margin: 0px;  
      //padding: 10px;
`;

export const AddOutline= styled.form`
      width: calc(100% - 24px);
      padding: 12px;
      background-color: white;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export const FormControl = styled.div`
      display: flex;
      flex-direction: row;
      gap: 10px;
`;

export const Label = styled.label`
      flex: 1 0 auto;
      color: #434343;
      font-weight: 600;
`;
 
export const Input= styled.input`
      width: 100%;
      flex: 0 1 auto;
      border-radius: 5px;
      border: none;
      padding: 5px 0px 5px 5px;
      background-color: #e6eaee;
      font-weight: 600;
`;

const JoinClassButton = styled.button`
      max-width: 75px;
      background-color: #009dff;
      border-radius: 5px;
      color: #EFEFEF;
      text-decoration: none;
      padding: 10px;
      font-size: 12px;
      font-weight: 600;
      border: none;
      align-self: flex-end;
      margin-right: 0px 10px 0px 10px;
      cursor: pointer;
`;

export function ClassList() {
    const [currentData, setData] = useState(ClassListData);
    useEffect(() => {
      if (currentData.length == 0)
      SetUpClassListData(setData);
    }, []);
    const[isJoining, setIsJoining] = useState(false);  

    const joinClass = (e : any) => {
      e.preventDefault();
      let classCode = (document.getElementById('classCodeInput') as HTMLInputElement).value;
      JoinClassOnDB(classCode, setData);
      }

    return (
    <Wrapper>
      <Container>
            {/*<Title>Assignments</Title>*/}
            <AddOutline>
                  <Header>Join Class</Header>
                  <FormControl><Label>Enter Classroom Code </Label><Input type='text' id='classCodeInput' placeholder='Enter Classroom Code'></Input></FormControl>
                  <LoadingButton
                    onClick={joinClass}
                    loading={isJoining}
                    sx={{ ...loadingButtonStyles }}
                  >
                    <span>Join</span>
                  </LoadingButton>
            </AddOutline>
            <ClassBoxes currentData = {currentData}></ClassBoxes>
      </Container>
    </Wrapper>
    );
  }

  async function SetUpClassListData(setData: React.Dispatch<React.SetStateAction<ClassAssignmentsComponent[]>>)
  {
    console.log('setUpClassListData is called');
      var requestOptions: RequestInit = {
            method: 'GET',
            credentials: 'same-origin',
            headers: await getRequestHeaders()
      };

      let classData = [
                  {
                        "id": "6427c6f4043f915aff909ed4",
                        "name": "Math Outline 1",
                        "dueDate": "2023-04-01T05:00:00Z",
                        "urls": [
                        "https://KhanAcademy.com/Math/Addition",
                        "https://example.com/page2"
                        ]
                  }
            ]
      let classroomToAssignmentsMap = new Map<string, Assignment[]>();
      const fetchData = () => {
        console.log('fetch called');    
        fetch(`/api/users/assignments`, requestOptions)
           .then(response => response.json())
           .then(result => { console.log(result);
            for(let i = 0; i < result.length; i++)
            {
              let assignment = {
                'name': result[i].assignmentName,
                'id': result[i].id,
                'date': new Date(result[i].dueDate),
                'grade': 0,
                'link': result[i].urls[0]
                };
              if (!classroomToAssignmentsMap.has(result[i].classroomName)) {
                classroomToAssignmentsMap.set(result[i].classroomName, [])
              }
              classroomToAssignmentsMap.get(result[i].classroomName)?.push(assignment);
                  
            }
      
            let classList: ClassAssignmentsComponent[] = [];
            classroomToAssignmentsMap.forEach((value: Assignment[], key: string) => {
              classList.push({
                name: key,
                assignments: value
              });
            });

            setData(classList);
          })

            .catch(error => console.log('error', error))};
      
      fetchData();
  }

/*
{
                        name: string;
                        assignments: {
                            name: string;
                            date: Date;
                            grade: number;
                            link: string;
                        }[];
                  }*/

  /*function createClassData(data: ClassAssignmentsComponent) {
      let name = newClass.name;
      let assignments = newClass.assignments
      data.push({name, assignments});
      return {name, assignments};
  }*/

  async function JoinClassOnDB(joinCode: string, setData: React.Dispatch<React.SetStateAction<ClassAssignmentsComponent[]>>) 
{
    var requestOptions: RequestInit = {
      method: 'PUT',
      credentials: 'same-origin',
      headers: await getRequestHeaders(),
      body: JSON.stringify({
            "JoinCode": joinCode
      })
    };

    const response = await fetch(`/api/users/join`, requestOptions) //Need to change route so it returns all assignments/assignmentIds from new class
    let result = await response.json();
    let assignments = result.assignmentIds as string[];
    SetUpClassListData(setData);
}

export default ClassList