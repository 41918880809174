import React, { Key } from 'react'
import { useState, useEffect } from 'react';
import styled from 'styled-components'
import { BlueButtonWhiteText } from './Auth/Signup';
import { TrashIconWrapper } from './TeacherHomePage/SideBar';
import { getRequestHeaders } from '../Utils/requestHelpers';
import { ClassListMap } from './TeacherHomePage/OutlineList';
import { PopupMessage, PopupMessageProps } from './PopupMessage';
import CalendarPicker from './Calendar';
import { oneWeekFromNow } from './Calendar';

const Accordion= styled.div`
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
`;

const Default= styled.div`
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
`;

const ConfirmButton = styled(BlueButtonWhiteText)`
    margin-right: 32px;
`

const Checkbox = styled.input`
    margin-top: 15px;
`


function Assign(props: {index: Number, show: Number, outlineId: string,
    classListData: { classroom: string; code: string; id: string}[]}) {
    let classListData: ClassListMap = {};
    const [classIdsPicked, setClassIdsPicked] = useState<string[]>([]);
    const [outlinesToClassesMap, setOutlinesToClassesMap] = useState(classListData);
    const [dueDate, setDueDate] = useState<Date | null>(oneWeekFromNow());
    let [popupMessageProps, setPopupMessageProps] = useState<PopupMessageProps | null>(null);

    const classSelectedToggle = (code: string) => {
        if (!classIdsPicked.includes(code)) {
            setClassIdsPicked(classIdsPicked => [...classIdsPicked, code])
        }
        else {
            setClassIdsPicked(classIdsPicked.filter((value) => value != code))
        }
    }

    useEffect(() => {
        if (Object.keys(outlinesToClassesMap).length == 0)
           setUpMapData(props.classListData, setOutlinesToClassesMap)
        }, [props.classListData]);

    const assignToClassIds = async () => {
        console.log('assignToClassIds');
        if (dueDate) {
            let currentDate = new Date();
            if (dueDate < currentDate) {
                setPopupMessageProps({
                    popupType: 'Error',
                    message: 'The due date must be in the future.',
                    boldedMessage: 'Pick a new date',
                    setPopupMessageProps: setPopupMessageProps
                });
                return; // Exit the function if the due date is past
            }
            let assignHeader = await getRequestHeaders()
            for (let i = 0; i < classIdsPicked.length; i ++) {
                console.log(dueDate);
                var requestOptions: RequestInit = {
                    method: 'PATCH',
                    credentials: 'same-origin',
                    headers: assignHeader,
                body: JSON.stringify({
                    "DueDate": dueDate.toISOString().substring(0, 19),
                })
                };
                //setData([...currentData, addBox(outlineName, firstPageURL, outlineId)]);
                const sendData = () => {fetch(`/api/classrooms/${classIdsPicked[i]}/assign/${props.outlineId}`, requestOptions)
                    .then(() => {console.log("Success in assigning outline")})
                    .catch((error) => {alert(`Error occurred in assigning the outline: ${error}`)})
                }
                sendData();
            }
            setOutlinesToClassesMap((prevMap) => {
                let newMap = {...prevMap};
                if (newMap[props.outlineId]) {
                    newMap[props.outlineId].push(...classIdsPicked);
                } else {
                    newMap[props.outlineId] = classIdsPicked;
                }
                setPopupMessageProps({
                    popupType: 'Success',
                    message: 'Assignment was assigned.',
                    boldedMessage: 'Congrats!',
                    setPopupMessageProps: setPopupMessageProps
                });
                return newMap;
            });
        }
        else {
            setPopupMessageProps({
                popupType: 'Error',
                message: 'Something went wrong.',
                boldedMessage: 'Try again',
                setPopupMessageProps: setPopupMessageProps
            });
        }
    }

    const handleStoppingPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        console.log('Propagation shouldve stopped');
        e.stopPropagation();
    }

    if(props.index == props.show)    
    {  
        {console.log('Show is true')}
        return (
        <>
        <Accordion onClick = {(e) => handleStoppingPropagation(e)}> 
        {popupMessageProps ? <PopupMessage {...popupMessageProps} /> : null}
        <div className='classList'>
            <p>Pick Classes to Assign to</p>
            <TrashIconWrapper>
                {props.classListData.map((val: {classroom: string, code: string, id: string}, key: Key) => {
                    let checked = outlinesToClassesMap[props.outlineId] ? outlinesToClassesMap[props.outlineId].includes(val.id) : false; 
                    return (
                            <label key = {key}> {val.classroom}
                                <Checkbox type='checkbox' 
                                checked = {checked ? true : classIdsPicked.includes(val.id)} 
                                disabled = {outlinesToClassesMap[props.outlineId] ? outlinesToClassesMap[props.outlineId].includes(val.id) : false}
                                onChange = {() => classSelectedToggle(val.id)}></Checkbox>
                            </label>   
                    );
                })}
                <CalendarPicker label = {'Set Due Date For Assignment'} placeholder = {'Click to set date'} setDueDate = {setDueDate}/>
                <ConfirmButton onClick = {() => assignToClassIds()}>Confirm</ConfirmButton>
            </TrashIconWrapper>
        </div>     
        </Accordion></>
        )
    }
    else
    {
        return (<Default></Default>)
    }
  
}

//Runs once for every instance of Assign. Not sure why.
async function setUpMapData(currentData: { classroom: string; code: string; id: string}[], setData: React.Dispatch<React.SetStateAction<ClassListMap>>) {
    const requestOptions: RequestInit = {
      method: 'GET',
      credentials: 'same-origin',
      headers: await getRequestHeaders()
    };

    let map: ClassListMap = {};
    
    // Create an array of Promises
    const promises = [];
    for (let classListData of currentData) {
        const promise = fetch(`/api/classrooms/${classListData.id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                let outlineList = result.outlineIds;
                for (let outlineId of outlineList) {
                    if (map[outlineId]) {
                        map[outlineId].push(classListData.id);
                    } else {
                        map[outlineId] = [classListData.id];
                    }
                }
            })
            .catch(error => {
                console.log('error', error);
                throw error;
            });
        promises.push(promise);
    }

    // Wait for all promises to resolve before setting the state
    await Promise.all(promises);
    //console.log(map);
    setData(map);
  }
  
/*async function getClassFromId(assignmentId: string) {
    const requestOptions: RequestInit = {
      method: 'GET',
      credentials: 'same-origin',
      headers: await getRequestHeaders()
    };
  
    try {
      const response = await fetch(`/api/assignments/${assignmentId}`, requestOptions);
      const result = await response.json();
      //console.log(result);
      return assignment;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  }*/

export default Assign;