import React, { Key } from 'react'
import { useState } from 'react';
import styled from 'styled-components'
//import {SideBarData} from './SideBarData';

//const assignments= [{name: "test", date: new Date(), grade: 95, link: '#'}, {name: "test2", date: new Date(), grade: 100, link: '#'}]

const Accordion= styled.div`
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
    {//width: 100%;}
    grid-column-start: 1;
    grid-column-end: -1;
    padding: 10px;
    width: 100%;
    left: 0px;
`;

const Default= styled.div`
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
`;

const AssignmentItem= styled.li`
    display: contents;
`;

const NameDate= styled.div`
    //width: 200px;
    {//background: red;}
    {/*display: flexbox;
    flex-direction: row;*/}
    padding-left: 85px;
    height: 100%;
    margin-top: 20px;
`;

const Name= styled.a`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;  
    width: max-content;
    color: #3E3E3E;
    text-decoration: none;
    
`;

const EdDate= styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    {//width: max-content;}
    color: #636363;
    margin: 0px;
    width: auto;
`;

const Grade= styled.p`
    margin: 0px;
    grid-column: 2;
    text-align: right;
    position: relative;
    right: 1em;
    font-family: 'Inter';
    font-style: normal;
    line-height: 39px;
    color: #434343;
    
    font-weight: 600;
    font-size: 24px;
`;
const AssignmentBody = styled.div`
    height: 75px;
    width: 962px;
    position: relative;
    background-color: white;
    border: 1px solid black;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: -27.12%;
    border-radius: 5px;
    display: grid;
    align-items: center;
    margin-bottom: 20px;

    &:hover {
        background-color: #BDE3F6;
        cursor: pointer;
    }
`
const IconDiv = styled.div`
    position: absolute;
    width: 53px;
    height: 53px;
    left: 20px;
    top: 11px;

    background: #64ADD0;
    border-radius: 50%;
    text-align: center;
    color: white;
`
const IconText = styled.span`
    align-items: center;
    padding-top: 10px;
`
const Link = styled.a`
    display: contents;
    width: 100%;
    height: 100%;
`

function AssignmentDropdown(props: {index: Number, show: Number, assignments: {name: string, id: string, date: Date, grade: number, link: string}[]}) {
    const [currentData, setData] = useState(props.assignments);
    if(props.index == props.show)    
    {  
        {console.log('Show is true')}
        return (
        
        <Accordion> 
        {/*<div className='assignmentList'>*/}
            {currentData.map((val: {name: string, id: string, date: Date, grade: number, link: string}, key: Key) => {
                return (
                    <AssignmentBody key = {key}>
                        <Link href={`${val.link}?edworldo=solve&edworldo_id=${val.id}`}>
                            <AssignmentItem key={key}>
                            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
                            <IconDiv key = {key}>
                                <IconText className="material-symbols-outlined" style={{fontSize: '30px'}}>
                                    assignment
                                </IconText>
                            </IconDiv>
                                <NameDate>
                                    <Name>{val.name}</Name>
                                    <EdDate>Due {val.date.toLocaleString('en-US', {month: 'long'}) + " " + val.date.getDate()}</EdDate>
                                </NameDate>
                                <Grade> {val.grade} / 100</Grade>
                            </AssignmentItem>
                        </Link>
                    </AssignmentBody>
                );
            })}
        {/*</div>*/}     
        </Accordion>
        )
    }
    else
    {
        return (<Default></Default>)
    }
  
}

export default AssignmentDropdown