import React, { Key } from 'react'
//import { validateLocaleAndSetLanguage } from 'typescript'
import {SideBarData, getSideBar} from './SideBarData'
import CreateClass from './CreateClass'
import {useState} from 'react'
import styled/*, { createGlobalStyle }*/ from 'styled-components'
import Icon from '@mui/material/Icon'
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import { useEffect } from 'react'
import { getRequestHeaders } from '../../Utils/requestHelpers'
import { useContext } from 'react'
import { ProfileContext, Profile } from '../../HomePage'
import { LoadingButton } from "@mui/lab";
import { loadingButtonStyles } from '../../HomePage'
//import { removeClass } from './SideBarData'

/*const TrashCanIcon = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
  <style>
                                .material-symbols-outlined {
                                font-variation-settings:
                                'FILL' 0,
                                'wght' 400,
                                'GRAD' 0,
                                'opsz' 48
                                }
    </style>`; */

const Container = styled.aside`
    background-color: white;
    //border-right: 1px solid #636363;
    box-sizing: border-box;
    width: 25%;
    position: static;
    min-height: 100%;
    padding: 20px;
    @media (max-width: 560px) {
        width: 80%;
        margin: 5% 10% 5% 10%;
        border: none;
        border-radius: 5px;
        padding: 10px;
        padding-bottom: 15px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
`;

const SideBarHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 560px) {
        padding: 0%;
        margin: 10px;
    }
`;

const SideBarHeading = styled.div`
    font-size: 26px;
    color: #636363;
    font-weight: 700;  
`;

const CreateClassContainer = styled.div`
    padding: 10% 0% 10% 0%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    @media (max-width: 560px) {
        padding: 5% 0% 5% 0%;
    }
`;

const Input = styled.input.attrs({
    type: 'text',
    id: 'class-input',
    placeholder: 'Enter class name'
})`
    width:100%;
    border-radius: 5px;
    border: none;
    padding: 5px 0px 5px 5px;
    background-color: #e6eaee;
    font-weight: 600;
`;

const CreateClassButton = styled.button.attrs({
    key: 'createClass',
    
})`
    align-self: flex-end;
    background-color: #009dff;
    border-radius: 5px;
    border: none;
    color: #EFEFEF;
    text-decoration: none;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    @media (max-width: 560px) {
        margin-left: 10px;
        margin-bottom: 10px;
    }
    margin-top: 2px;
`;

const Classroom= styled.div`
    text-align: left;
    border-radius: 5px;
    padding: 2.5%;
    &:hover {
        background-color: #e6eaee;
    }
`

const ClassroomName= styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    /* identical to box height */
    text-align: left;
    padding-bottom: 2%
`

export const TrashIconWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

export const TrashIcon = styled(DeleteIcon)`
    padding-top: 5px;
    cursor: pointer;
    color: black;
`

const ClassroomCode= styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
    justify-content: left;
`

export function SideBar(props: {currentData: {classroom: string, code: string; id: string;}[], setData: React.Dispatch<React.SetStateAction<{ classroom: string, code: string, id: string }[]>>}) {
    let { profile } = useContext(ProfileContext);
    const [isCreatingClass, setIsCreatingClass] = useState(false);
    profile = profile as Profile;
    //console.log(profile.id);
    let setData = props.setData;
    let currentData = props.currentData;
    useEffect(() => {
        const fetchData = async () => {
          await GetSideBarData(setData, currentData);
        };
        if (currentData.length == 0)
        fetchData();
      }, []);
    const addData = async () => {
        profile = profile as Profile;
        const newClass = await CreateClass(profile.id, (document.getElementById('class-input') as HTMLInputElement)?.value);
        //console.log(newClass);
        if (currentData.length == 0 || currentData[currentData.length - 1]?.id != newClass.id)
        setData(currentData => [...currentData, newClass]);
    }

    const removeClass = (id: string) => {
        removeClassOnDB(id);
        setData(currentData => currentData.filter(item => item.id !== id));
    }

    return (
        <Container>
            <SideBarHeader>
                <SideBarHeading>Classes</SideBarHeading>
            </SideBarHeader>
            <CreateClassContainer>
                <Input/>
                <LoadingButton
                onClick={addData}
                loading={isCreatingClass}
                sx={{ ...loadingButtonStyles }}
                >
                <span>Create</span>
                </LoadingButton>
            </CreateClassContainer>

            {/*<ul className='sidebarList'>*/}
                {currentData.map((val: {classroom: string, code: string, id: string}, key: Key) => {
                    return (
                        <Classroom key={key}>
                            <TrashIconWrapper><ClassroomName className='classroom'>{val.classroom}</ClassroomName>
                            <TrashIcon onClick = {() => removeClass(val.id)}>delete</TrashIcon></TrashIconWrapper>
                            <ClassroomCode>
                                {val.code}
                            </ClassroomCode>
                            {/*<Box component="span" sx={visuallyHidden}>Delete Class</Box>*/}
                        </Classroom>
                    );
                })}
            {/*</ul>*/}

        </Container>
  );
}

async function GetSideBarData(setData: React.Dispatch<React.SetStateAction<{classroom: string; code: string; id: string}[]>>, 
    currentData: { classroom: string; code: string; id: string}[])
  {
    
      var requestOptions: RequestInit = {
            method: 'GET',
            credentials: 'same-origin',
            headers: await getRequestHeaders()
      };

        type data = 
        {
            "id": string,
            "teacherEmail": string,
            "name": string,
            "code": string,
            "outlineIds": string,
            "studentEmails": string[]
        }[];
      
        const fetchData = () => {fetch(`/api/users/classrooms`, requestOptions)
           .then(response => response.json())
           .then(result => {
            for(let i = 0; i < result.length; i++)
            {
                setData(currentData => [...currentData, {"classroom": result[i].name, "code": result[i].joinCode, "id": result[i].id}]);
            }
           })
           .catch(error => console.log('error', error));
            }
        fetchData();
}

const removeClassOnDB =  async (classroomId: string) =>
  {
      var requestOptions: RequestInit = {
            method: 'DELETE',
            credentials: 'same-origin',
            headers: await getRequestHeaders()
      };
      
        const fetchData = () => {fetch(`/api/classrooms/${classroomId}`, requestOptions)
           .then(() => console.log("Classroom Deleted"))
           .catch(error => console.log('error', error));
            }
        fetchData();
}

/*function SetUpSideBarData(data: [{id: string, teacherEmail: string, 
      name: string, pages: [{url: string, questions: []}]}])
{
      for(let i = 0; i < data.length; i++)
      {
            setData([currentData..., {"classroom": classroom, "code": "placeholder"}]);
      }
}*/


export default SideBar;