import React from 'react'
import { useState, useEffect, useContext } from 'react';
//import { render } from '@testing-library/react';
import { addBox, OutlineListData } from './OutlineListData';
import styled from 'styled-components';
import Boxes from '../Boxes';
import { resourceLimits } from 'worker_threads';
import { SideBarData } from './SideBarData';
import SideBar from './SideBar';
import { ProfileContext, Profile } from '../../HomePage';
import { getRequestHeaders } from '../../Utils/requestHelpers';
import { loadingButtonStyles } from "../../HomePage";
import { LoadingButton } from "@mui/lab";
import { BlueButtonWhiteText } from '../Auth/Signup';
import AssignmentList from '../AssignmentGrades/AssignmentList';
import { type } from 'os';

export interface ClassListMap {
      [outlineId: string]: string[];
    }

export type OutlineData = {
      name: string;
      link: string;
      outlineId: string;
}[]

const Container= styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      margin: 5% 10% 5% 10%;
`;

const Header = styled.h1`
      font-size: 26px;
      color: #636363;
      font-weight: 700;
      margin: 0px;
      padding-top: 10px;  
`;

const TopContent = styled.form`
      width: calc(100% - 24px);
      padding: 12px;
      background-color: white;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const FormControl = styled.div`
      display: flex;
      flex-direction: row;
      gap: 10px;
`;

const Label = styled.label`
      flex: 1 0 auto;
      color: #434343;
      font-weight: 600;
`;
 
const Input = styled.input`
      width: 100%;
      flex: 0 1 auto;
      border-radius: 5px;
      border: none;
      padding: 5px 0px 5px 5px;
      background-color: #e6eaee;
      font-weight: 600;
`;

const CreateAssignmentButton = styled.button`
      max-width: 75px;
      background-color: #009dff;
      border-radius: 5px;
      color: #EFEFEF;
      text-decoration: none;
      padding: 10px;
      font-size: 12px;
      font-weight: 600;
      border: none;
      align-self: flex-end;
      margin-right: 0px 10px 0px 10px;
      cursor: pointer;
`;

const GoBackBtnWrapper = styled.div`
      padding-top: 50px;
      padding-bottom: 13px;
`;


export function OutlineList() {
    let data: {name: string, link: string, outlineId: string}[] = [];
    
    const [currentData, setData] = useState(data);
    const [currentClassListData, setClassListData] = useState(SideBarData);
    const [isAddingAssignment, setIsAddingAssignment] = useState(false);
    const [showAssignmentList, setShowAssignmentList] = useState<string | null>(null);
    const [headerText, setHeaderText] = useState("Assignments");
    let { profile } = useContext(ProfileContext);
    profile = profile as Profile;
    const addData= (e : any) => {
      e.preventDefault();
      let outlineName = (document.getElementById('outlineInput') as HTMLInputElement).value;
      let firstPageURL = (document.getElementById('linkInput') as HTMLInputElement).value;
      UpdateOutlineOnDB(outlineName, firstPageURL, setData);
      }
      useEffect(() => {
      if (currentData.length == 0)
      SetUpOutlineListData(setData, currentData)
      }, []); //lag slow in adding boxes

      const handleShowAssignment = () => {
            setShowAssignmentList(null);
            setHeaderText("Assignments");
        }
    
    return (
      <><SideBar currentData = {currentClassListData} setData = {setClassListData}/>
      <Container>
            <TopContent>
            <Header>{headerText}</Header>
            {showAssignmentList ? <GoBackBtnWrapper><BlueButtonWhiteText onClick = {handleShowAssignment}>Go Back to Full Assignment List</BlueButtonWhiteText></GoBackBtnWrapper> :
            <><FormControl><Label>Assignment Name: </Label><Input type='text' id='outlineInput' placeholder='Assignment 1'></Input></FormControl>
            <FormControl><Label>First Page: </Label><Input type='text' id='linkInput' placeholder='https://example.com'></Input></FormControl>
            <LoadingButton
                  onClick={addData}
                  loading={isAddingAssignment}
                  sx={{ ...loadingButtonStyles }}
            >
                  <span>Create</span>
            </LoadingButton></>}
            </TopContent>
            {showAssignmentList ? <AssignmentList outlineId = {showAssignmentList}/> :
            <Boxes currentData={currentData} setShowAssignmentList = {setShowAssignmentList} 
            classListData = {currentClassListData} setData = {setData} setHeaderText = {setHeaderText}/>
            }   
            
      </Container></>
      )
    }

async function UpdateOutlineOnDB(outlineName: string, Url: string, setData: React.Dispatch<React.SetStateAction<{
      name: string;
      link: string;
      outlineId: string;
  }[]>>) 
{
    var requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'same-origin',
      headers: await getRequestHeaders(),
      body: JSON.stringify({
      "Name": outlineName,
      "Pages": [
            {
                  "Url": Url,
                  "Questions": []
            }
      ]
    })
    };
    //setData([...currentData, addBox(outlineName, firstPageURL, outlineId)]);
    const sendData = async () => {fetch(`/api/outlines`, requestOptions)
      .then(response => response.json())
      .then(result => {
            setData(currentData => [...currentData, addBox(outlineName, Url, result.id)]);
      })
      .then(() => {console.log("Success in creating outline")})
      .catch(() => {alert("Error occurred in creating the class")})
    }
    sendData();
    //useEffect(() => sendData, [])
}

async function SetUpOutlineListData(setData: React.Dispatch<React.SetStateAction<{name: string; link: string; outlineId: string;}[]>>, currentData: {
            name: string;
            link: string;
            outlineId: string;
        }[])
      {
      
      var requestOptions: RequestInit = {
            method: 'GET',
            //credentials: 'same-origin',
            mode: 'cors',
            headers: await getRequestHeaders()
      };

      const fetchData = () => {fetch(`/api/users/outlines`, requestOptions)
           .then(response => response.json())
           .then(result => { console.log(result);
            
            for(let i = 0; i < result.length; i++)
            {
                  let name = (result[i]?.name) ? result[i].name : "Error";
                  let pageOneUrl = (result[i]?.pages[0]?.url) ? result[i].pages[0].url : "google.com"; //Replace with custom error page
                  let id = (result[i]?.id) ? result[i].id : "Error"; //Replace with error Id
                  console.log(pageOneUrl);
                  setData(currentData => [...currentData, addBox(name, pageOneUrl, id)]);
            }}) /*return result*/
            .catch(error => console.log('error', error))//, []);
      }
      //useEffect(() => fetchData, [])
      fetchData();
}


export default OutlineList